import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Sort, SortDirection } from '../../lib/types';
import SortButton, { ISortButtonProps } from '../sort-button/sort-button';
// import { employeeSortAtom, employeeSortDirectionAtom } from '../../recoil/employee-sort';
// import { employeeFiltersAtom } from '../../recoil/employee-filters';
import { firmSortAtom, firmSortDirectionAtom } from '../../recoil/firm-sort-filter';
import { firmInfoFiltersAtom } from '../../recoil/firm-search';
import './employee-sort-filter.scss';

export default function EmployeeSort(): JSX.Element {
   // const [sort, setSort] = useRecoilState(employeeSortAtom);
   // const [sortDirection, setSortDirection] = useRecoilState(employeeSortDirectionAtom);
   // const employeeFilters: string[] = useRecoilValue(employeeFiltersAtom);

   // const handleSortChange = (newSort: Sort) => {
   //    // Toggle direction when the same sort is clicked
   //    if (sort === newSort) {
   //       setSortDirection((prevDirection) => 
   //          prevDirection === SortDirection.ascending 
   //             ? SortDirection.descending 
   //             : SortDirection.ascending
   //       );
   //    } else {
   //       // Change sort and default to ascending
   //       setSort(newSort);
   //       setSortDirection(SortDirection.ascending);
   //    }
   // };

   // const sortButtonProps: ISortButtonProps = {
   //    disabled: false,
   //    sortAtom: employeeSortAtom,
   //    sorts: [
   //       { 
   //          sort: Sort.employeeName, 
   //          ascendingDescending: true 
   //       }
   //    ],
   //    onSortChange: handleSortChange,
   // };

   const [sort, setSort] = useRecoilState(firmSortAtom);
   const [sortDirection, setSortDirection] = useRecoilState(firmSortDirectionAtom);
   const firmFilters: string[] = useRecoilValue(firmInfoFiltersAtom);

   const handleSortChange = (newSort: Sort) => {
      // Toggle direction when the same sort is clicked
      if (sort === newSort) {
         setSortDirection((prevDirection) => 
            prevDirection === SortDirection.ascending 
               ? SortDirection.descending 
               : SortDirection.ascending
         );
      } else {
         // Change sort and default to ascending
         setSort(newSort);
         setSortDirection(SortDirection.ascending);
      }
   };

   const sortButtonProps: ISortButtonProps = {
      disabled: false,
      sortAtom: firmSortAtom,
      sorts: [
         { 
            sort: Sort.organizationName, 
            ascendingDescending: true 
         },
      ],
      onSortChange: handleSortChange,
   };

   return (
      <div className="employee-sort-filter">
         <div className="employee-filters-sort">
            <SortButton
               disabled={sortButtonProps.disabled}
               sortAtom={sortButtonProps.sortAtom}
               sorts={sortButtonProps.sorts}
               onSortChange={handleSortChange}
            />
         </div>
      </div>
   );
}
