import React, { useRef } from 'react';
import './modal-textbox.scss';

export interface IModalTextboxProps {
   labelText: string;
   textState: string;
   maxLength?: number;
   setTextState: React.Dispatch<React.SetStateAction<string>>;
   isSelect?: boolean; // Determines if this is a select dropdown
   options?: Array<{ value: string; label: string }>; // Options for the dropdown
}

export default function ModalTextbox(props: IModalTextboxProps): JSX.Element {
   const [textboxClass, setTextboxClass] = React.useState('');
   const inputRef = useRef<HTMLInputElement | HTMLSelectElement>(null);

   return (
      <div
         className={`modal-textbox ${textboxClass}`}
         onClick={() => {
            if (inputRef.current) inputRef.current.focus();
         }}
      >
         <div className="modal-textbox-label body-small-regular">
            {props.labelText}
            {props.labelText !== 'Employee Contact' ? (
               <span className="modal-textbox-required body-small-regular">
                  {' '}
                  *
               </span>
            ) : (
               ''
            )}
         </div>

         {props.isSelect && props.options ? (
            // Render select dropdown
            <select
               className="modal-textbox-input body-large-regular"
               onFocus={() => setTextboxClass('modal-textbox-focused')}
               onBlur={() => setTextboxClass('')}
               onChange={(e) =>
                  props.setTextState((e.target as HTMLSelectElement).value)
               }
               value={props.textState}
               ref={inputRef as React.RefObject<HTMLSelectElement>}
            >
               <option value="">Select Employee Role</option>
               {props.options.map((option) => (
                  <option key={option.value} value={option.value}>
                     {option.label}
                  </option>
               ))}
            </select>
         ) : (
            // Render input box
            <input
               className="modal-textbox-input body-large-regular"
               maxLength={props.maxLength}
               onFocus={() => setTextboxClass('modal-textbox-focused')}
               onBlur={() => setTextboxClass('')}
               onChange={(e) =>
                  props.setTextState((e.target as HTMLInputElement).value)
               }
               value={
                  props.labelText === 'Employee Contact' &&
                  !props.textState.startsWith('+1')
                     ? `+1${props.textState}`
                     : props.textState
               }
               ref={inputRef as React.RefObject<HTMLInputElement>}
            />
         )}
      </div>
   );
}
